<template>
  <b-card>
    <FormView
      :data="$store.getters['withdrawal/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'
import { withdrawalViewPayment } from '@/list/payment-method'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'createdAt',
          label: 'Requested at',
          type: 'datetime',
        },
        {
          key: 'status',
          label: 'Status',
          type: 'dot',
          sortable: false,
          map: {
            approved: 'success',
            pending: 'warning',
            rejected: 'danger',
          },
        },
        // all value is in usd
        {
          key: 'amount',
          label: 'Amount',
          prefix: '$',
          suffix: ' USD',
        },
        withdrawalViewPayment,
        {
          key: 'updatedAt',
          label: 'Paid at',
          type: 'datetime',
        },
        {
          key: 'reason',
          label: 'Reason',
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('withdrawal/getDetail', id)
    },
  },
}
</script>

  <style scoped>

  </style>
